@charset "utf-8";

:root {
    --background-color: #F0F0F0;
    --background-alt-color: #DDD;
    --text-color: #000;
    --tint-color: #350ce9;
    --tint-compliment-color: #c3b6fb;
}
* {
    box-sizing: border-box;
}
html,
body {
    font-family: Georgia, serif;
    font-size: 16px;
    background-color: var(--background-color);
    color: var(--text-color);
    margin: 0;
    padding: 0;
}
body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}
a:link,
a:visited {
    color: inherit;
    text-decoration: underline var(--tint-compliment-color);
}
a:hover {
    color: var(--tint-color);
}
p {
    line-height: 1.5;
}
.screen {
    flex: 1;
    display: grid;
    grid-template-columns: 1rem auto 1rem;
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
}
.container {
    display: flex;
    flex-direction: column;
    grid-column-start: 2;
    grid-column-end: 3;
}
.bio {
    flex: 1;
    display: flex;
    flex-direction: column;
}
.profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
.photo {
    width: 16rem;
    height: 16rem;
    border-radius: 8rem;
    margin: 1rem 0 0;
}
.name {
    font-size: 2.5rem;
}
.links {
    font-size: 1.5rem;
    font-style: italic;
    text-align: center;
    margin-bottom: 2rem;
}
.links ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
.links ul li {
    margin: 1rem 0;
    padding: 0;
}
.links hr,
.rule {
    margin: 2rem auto;
    max-width: 10rem;
    opacity: 0.8;
    border-color: #CCC;
    border-style: solid;
    border-width: 1px 0 0 0;
}
.button {
    display: inline-flex;
    align-items: center;
    padding: 0.5rem 1rem;
    border-radius: 3rem;
    transition-duration: 200ms;
    transition-timing-function: ease-in-out;
    transition-property: color, background-color;
}
.button:hover {
    background-color: var(--tint-color);
    color: #FFF;
}
.button .symbol {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    margin-right: .5rem;
    overflow: hidden;
    opacity: 0.8;
}
.button:hover .symbol {
    opacity: 1;
}
.extensions {
    max-width: 50rem;
    margin: 0 auto;
}
.extensions h1 {
    font-size: 3rem;
    text-align: center;
}
.extension {
    margin-bottom: 2rem;
}
.extension .extension__name {
    font-size: 2rem;
    text-align: center;
}
.extension .extension__screenshots {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2rem;
    margin-bottom: 2rem;
}
.extension .extension__screenshots a:hover {
    background-color: var(--tint-compliment-color);
}
.extension .extension__screenshots a img {
    display: block;
}
.extension .extension__links {
    text-align: center;
}
.label {
    display: inline-block;
    vertical-align: middle;
    background-color: var(--background-alt-color);
    border-radius: 0.5rem;
    font-size: 1rem;
    padding: 0.25rem 0.5rem;
}
.message {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    text-align: center;
}
.message .message__title {
    font-size: 3rem;
    margin-top: 4rem;
    margin-bottom: 2rem;
}
.message .message__link {
    margin-top: 2rem;
    font-size: 1.2rem;
}
.footer {
    text-align: center;
    background-color: var(--background-alt-color);
    overflow: hidden;
    padding-bottom: env(safe-area-inset-bottom);
}

@media screen and (min-width: 48rem) {
    .screen {
        grid-template-columns: auto 46rem auto;
        min-height: 100vh;
    }
    .bio {
        flex-direction: row;
        align-items: center;
    }

    .profile,
    .links {
        flex: 1;
    }
    .links {
        margin-bottom: 0;
    }
}
@media screen and (min-width: 62rem) {
    .screen {
        grid-template-columns: auto 60rem auto;
    }
}
@media screen and (min-width: 75rem) {
    .screen {
        grid-template-columns: auto 73rem auto;
    }
}

@media (prefers-color-scheme: dark) {
    :root {
        --background-color: #000;
        --background-alt-color: #222;
        --text-color: #FFF;
    }
}